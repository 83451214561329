<template>
  <div class="first-screen">
    <h1>
      <b>Повысьте<br />эффективность</b><br />вашей команды
    </h1>
    <p class="lead">
      Мышление отвечает за&nbsp;все провалы Ваших ожиданий от&nbsp;команд
      и&nbsp;сотрудников. Конвентус моделирует поведение сотрудников
      и&nbsp;команд в&nbsp;различных условиях с&nbsp;помощью проективной
      онлайн-оценки мышления
    </p>
    <a href="/static/Conventus-deck-18.03.2021.pdf" download class="action-btn"
      >Скачать презентацию</a
    >
  </div>
</template>

<script>
export default {
  name: "Index",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.action-btn {
  background: #00e087;
  color: white;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 35px;
  text-decoration: none;

  transition: all 0.25s ease 0s;
  cursor: pointer;

  @media (max-width: 500px) {
    font-size: 18px;
  }
  &:hover {
    background: #02c879;
  }
}

.first-screen {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lead {
  color: rgba(black, 0.5);
  max-width: 817px;
  font-size: 24px;
  line-height: 140%;

  @media (max-width: 500px) {
    font-size: 18px;
  }
}

h1 {
  font-size: 73px;
  max-width: 1203px;
  font-weight: normal;

  color: black;

  margin-top: 90px;
  margin-bottom: 30px;

  @media (max-width: 500px) {
    font-size: 36px;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
