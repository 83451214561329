<template>
  <div id="app">
    <Toolbar />
    <Index />
    <Skillaz />
    <Clients />

    <div
      class="pipedriveWebForms"
      data-pd-webforms="https://pipedrivewebforms.com/form/36135834cb67165530de3fa70237d7266058150"
    ></div>
  </div>
</template>

<script>
import Index from "./components/Index.vue";
import Toolbar from "./components/Toolbar.vue";
import Clients from "./components/Clients.vue";
import Skillaz from "./components/Skillaz.vue";

export default {
  name: "App",
  components: {
    Index,
    Toolbar,
    Clients,
    Skillaz,
  },
};
</script>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 13px;
}
</style>
