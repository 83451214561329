<template>
  <div class="clinets-wrapper">
    <div class="content">
      <!-- <img v-for="n in 9" class="client-logo" :src=`../assets/${n}.png` /> -->
      <img
        v-for="client in clients"
        :key="client.url"
        class="client-logo"
        :class="client.customClass"
        :src="require(`../assets/${client.url}`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "clients",
  data() {
    return {
      clients: [
        {
          url: "1.png",
          customClass: "logo-custom-1",
        },
        {
          url: "2.png",
          customClass: "",
        },
        {
          url: "3.jpg",
          customClass: "logo-custom-3",
        },
        {
          url: "4.png",
          customClass: "",
        },
        {
          url: "5.png",
          customClass: "",
        },
        {
          url: "6.png",
          customClass: "",
        },
        {
          url: "7.png",
          customClass: "",
        },
        {
          url: "8.png",
          customClass: "",
        },
        {
          url: "9.jpg",
          customClass: "",
        },
        {
          url: "14.jpg",
          customClass: "logo-custom-14",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.clinets-wrapper {
  display: flex;
  align-items: center;
  overflow: visible;
  margin-top: 90px;
  margin-bottom: 50px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.client-logo {
  margin-right: 40px;
  margin-bottom: 30px;
  width: 10%;

  @media (max-width: 800px) {
    width: 34%;
  }
}

.client-logo:nth-child(2n) {
  @media (max-width: 700px) {
    margin-right: 0;
  }
}

.logo-custom-1 {
  margin-top: -16px;
}
.logo-custom-3 {
  @media (min-width: 700px) {
    width: 13%;
  }
}

.logo-custom-14 {
  @media (min-width: 700px) {
    width: 13%;
  }
}

// @import "./base.scss";
.swiper-slide {
  width: 80%;
}
.swiper-slide:nth-child(2n) {
  width: 60%;
}
.swiper-slide:nth-child(3n) {
  width: 40%;
}
</style>
