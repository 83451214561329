<template>
  <div class="tooltip">
    <div class="logo-wrapper">
      <img alt="Conventus" src="../assets/logo.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.tooltip {
  display: flex;
  margin-left: 10px;
}
.logo-wrapper {
}
</style>
